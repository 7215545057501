import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
import { DonationFormFragmentFragmentDoc, DonationFormAmountFragmentFragmentDoc, QuestionFragmentFragmentDoc } from './fragments';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCampaignWithVolumeQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['String'];
}>;


export type GetCampaignWithVolumeQuery = { __typename?: 'Query', getCampaignWithVolume?: { __typename?: 'DonationFormResponseObject', object?: { __typename?: 'DonationFormObject', id: string, volume?: number | null, logoUrl?: string | null, target?: number | null, redirectUrl?: string | null, isPrimaryCampaign: boolean, isPrimaryTeam: boolean, teamId?: string | null, campaignId?: string | null, allowCheque: boolean, allowInHonour: boolean, bannerUrl?: string | null, bannerVideoUrl?: string | null, category: Types.DonationFormCategory, color: string, encourageMonthlyDonations: boolean, hasReceipt: boolean, hideFundraiseButton: boolean, formVolume?: number | null, path: string, isArchived: boolean, locale: Types.Locales, notificationEmails?: GraphQL_NotificationEmailsScalar | null, stripeProductId?: string | null, askForAddress: boolean, allowTeamCreation?: boolean | null, allowFundraiserCreation?: boolean | null, ticketingId?: string | null, organizationId?: string | null, user?: { __typename?: 'UserObject', email?: string | null } | null, organization?: { __typename?: 'OrganizationObject', name: string, region?: string | null, country: Types.OrganizationCountry, stripeCustomAccountId?: string | null, phoneNumber?: string | null, hasAdministrativeInformation: boolean, hasCharityNumber: boolean, canGenerateReceipt: boolean, deprecatedMongoId?: string | null, signatureUrl?: string | null } | null, donationFormFields?: Array<{ __typename?: 'DonationFormFieldObject', id: string, title: string, description: string, emailContent?: string | null, emailObject?: string | null, chequeDescription?: string | null, locale: Types.Locales, postDonationUrl?: string | null, donationFormAmounts?: Array<{ __typename?: 'DonationFormAmountObject', id: string, amount: number, isRecurrent: boolean, message?: string | null, sortIndex: number, recurrenceInterval: Types.ProductDonationRecurrenceInterval }> | null }> | null } | null } | null };

export type CreateDraftCampaignMutationVariables = Types.Exact<{
  donationForm: Types.CreateDraftDonationFormInput;
}>;


export type CreateDraftCampaignMutation = { __typename?: 'Mutation', createDraftCampaign: { __typename?: 'DonationFormObjectT8zdfs9n', object?: { __typename?: 'DonationFormObject', id: string, campaignId?: string | null, teamId?: string | null, target?: number | null, isPrimaryCampaign: boolean, isPrimaryTeam: boolean, category: Types.DonationFormCategory } | null } };

export type GetCampaignSubFormsCountQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['String'];
}>;


export type GetCampaignSubFormsCountQuery = { __typename?: 'Query', getCampaignSubFormsCount: { __typename?: 'NumberResponseObject', object?: number | null } };

export type GetFundraisingOrganizationQueryVariables = Types.Exact<{
  donationFormId: Types.Scalars['String'];
}>;


export type GetFundraisingOrganizationQuery = { __typename?: 'Query', getFundraisingOrganization: { __typename?: 'OrganizationResponseObject', object?: { __typename?: 'OrganizationObject', id: string, name: string, address?: string | null, city?: string | null, postalCode?: string | null, country: Types.OrganizationCountry, region?: string | null, phoneNumber?: string | null, charityNumber?: string | null, stripeCustomAccountId?: string | null, signatureUrl?: string | null, logoUrl?: string | null, hasAdministrativeInformation: boolean, hasCharityNumber: boolean, canGenerateReceipt: boolean, deprecatedMongoId?: string | null, volumeThresholdReached?: number | null, bankDescriptor?: string | null, referralQuestion?: Types.ReferralQuestionChoice | null, referralQuestionOther?: string | null, cause?: Types.OrganizationCause | null, website?: string | null, stripeUserId?: string | null, isStripeCustomAccountActive: boolean, type?: Types.OrganizationType | null } | null } };

export type GetCampaignParticipantContactsQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['String'];
}>;


export type GetCampaignParticipantContactsQuery = { __typename?: 'Query', getCampaignParticipantContacts: { __typename?: 'ContactObjectjMQeShZ3', items?: Array<{ __typename?: 'ContactObject', id: string, firstName?: string | null, lastName?: string | null, userId: string, user?: { __typename?: 'UserObject', email?: string | null } | null }> | null } };


export const GetCampaignWithVolumeDocument = gql`
    query getCampaignWithVolume($campaignId: String!) {
  getCampaignWithVolume(campaignId: $campaignId) {
    object {
      id
      volume
      ...DonationFormFragment
      user {
        email
      }
      organization {
        name
        region
        country
        stripeCustomAccountId
        phoneNumber
        hasAdministrativeInformation
        hasCharityNumber
        canGenerateReceipt
        deprecatedMongoId
        signatureUrl
      }
      logoUrl
      target
      redirectUrl
      donationFormFields {
        id
        title
        description
        emailContent
        emailObject
        chequeDescription
        locale
        postDonationUrl
        donationFormAmounts {
          ...DonationFormAmountFragment
        }
      }
      isPrimaryCampaign
      isPrimaryTeam
      teamId
      campaignId
    }
  }
}
    ${DonationFormFragmentFragmentDoc}
${DonationFormAmountFragmentFragmentDoc}`;

/**
 * __useGetCampaignWithVolumeQuery__
 *
 * To run a query within a React component, call `useGetCampaignWithVolumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignWithVolumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignWithVolumeQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignWithVolumeQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignWithVolumeQuery, GetCampaignWithVolumeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignWithVolumeQuery, GetCampaignWithVolumeQueryVariables>(GetCampaignWithVolumeDocument, options);
      }
export function useGetCampaignWithVolumeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignWithVolumeQuery, GetCampaignWithVolumeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignWithVolumeQuery, GetCampaignWithVolumeQueryVariables>(GetCampaignWithVolumeDocument, options);
        }
export type GetCampaignWithVolumeQueryHookResult = ReturnType<typeof useGetCampaignWithVolumeQuery>;
export type GetCampaignWithVolumeLazyQueryHookResult = ReturnType<typeof useGetCampaignWithVolumeLazyQuery>;
export type GetCampaignWithVolumeQueryResult = Apollo.QueryResult<GetCampaignWithVolumeQuery, GetCampaignWithVolumeQueryVariables>;
export const CreateDraftCampaignDocument = gql`
    mutation createDraftCampaign($donationForm: CreateDraftDonationFormInput!) {
  createDraftCampaign(donationForm: $donationForm) {
    object {
      id
      campaignId
      teamId
      target
      isPrimaryCampaign
      isPrimaryTeam
      category
    }
  }
}
    `;
export type CreateDraftCampaignMutationFn = Apollo.MutationFunction<CreateDraftCampaignMutation, CreateDraftCampaignMutationVariables>;

/**
 * __useCreateDraftCampaignMutation__
 *
 * To run a mutation, you first call `useCreateDraftCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftCampaignMutation, { data, loading, error }] = useCreateDraftCampaignMutation({
 *   variables: {
 *      donationForm: // value for 'donationForm'
 *   },
 * });
 */
export function useCreateDraftCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CreateDraftCampaignMutation, CreateDraftCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDraftCampaignMutation, CreateDraftCampaignMutationVariables>(CreateDraftCampaignDocument, options);
      }
export type CreateDraftCampaignMutationHookResult = ReturnType<typeof useCreateDraftCampaignMutation>;
export type CreateDraftCampaignMutationResult = Apollo.MutationResult<CreateDraftCampaignMutation>;
export type CreateDraftCampaignMutationOptions = Apollo.BaseMutationOptions<CreateDraftCampaignMutation, CreateDraftCampaignMutationVariables>;
export const GetCampaignSubFormsCountDocument = gql`
    query getCampaignSubFormsCount($campaignId: String!) {
  getCampaignSubFormsCount(campaignId: $campaignId) {
    object
  }
}
    `;

/**
 * __useGetCampaignSubFormsCountQuery__
 *
 * To run a query within a React component, call `useGetCampaignSubFormsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignSubFormsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignSubFormsCountQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignSubFormsCountQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignSubFormsCountQuery, GetCampaignSubFormsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignSubFormsCountQuery, GetCampaignSubFormsCountQueryVariables>(GetCampaignSubFormsCountDocument, options);
      }
export function useGetCampaignSubFormsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignSubFormsCountQuery, GetCampaignSubFormsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignSubFormsCountQuery, GetCampaignSubFormsCountQueryVariables>(GetCampaignSubFormsCountDocument, options);
        }
export type GetCampaignSubFormsCountQueryHookResult = ReturnType<typeof useGetCampaignSubFormsCountQuery>;
export type GetCampaignSubFormsCountLazyQueryHookResult = ReturnType<typeof useGetCampaignSubFormsCountLazyQuery>;
export type GetCampaignSubFormsCountQueryResult = Apollo.QueryResult<GetCampaignSubFormsCountQuery, GetCampaignSubFormsCountQueryVariables>;
export const GetFundraisingOrganizationDocument = gql`
    query getFundraisingOrganization($donationFormId: String!) {
  getFundraisingOrganization(donationFormId: $donationFormId) {
    object {
      id
      name
      address
      city
      postalCode
      country
      region
      phoneNumber
      charityNumber
      stripeCustomAccountId
      signatureUrl
      logoUrl
      hasAdministrativeInformation
      hasCharityNumber
      canGenerateReceipt
      deprecatedMongoId
      volumeThresholdReached
      bankDescriptor
      referralQuestion
      referralQuestionOther
      cause
      website
      stripeUserId
      stripeCustomAccountId
      isStripeCustomAccountActive
      type
    }
  }
}
    `;

/**
 * __useGetFundraisingOrganizationQuery__
 *
 * To run a query within a React component, call `useGetFundraisingOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFundraisingOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFundraisingOrganizationQuery({
 *   variables: {
 *      donationFormId: // value for 'donationFormId'
 *   },
 * });
 */
export function useGetFundraisingOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetFundraisingOrganizationQuery, GetFundraisingOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFundraisingOrganizationQuery, GetFundraisingOrganizationQueryVariables>(GetFundraisingOrganizationDocument, options);
      }
export function useGetFundraisingOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFundraisingOrganizationQuery, GetFundraisingOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFundraisingOrganizationQuery, GetFundraisingOrganizationQueryVariables>(GetFundraisingOrganizationDocument, options);
        }
export type GetFundraisingOrganizationQueryHookResult = ReturnType<typeof useGetFundraisingOrganizationQuery>;
export type GetFundraisingOrganizationLazyQueryHookResult = ReturnType<typeof useGetFundraisingOrganizationLazyQuery>;
export type GetFundraisingOrganizationQueryResult = Apollo.QueryResult<GetFundraisingOrganizationQuery, GetFundraisingOrganizationQueryVariables>;
export const GetCampaignParticipantContactsDocument = gql`
    query getCampaignParticipantContacts($campaignId: String!) {
  getCampaignParticipantContacts(campaignId: $campaignId) {
    items {
      id
      firstName
      lastName
      userId
      user {
        email
      }
    }
  }
}
    `;

/**
 * __useGetCampaignParticipantContactsQuery__
 *
 * To run a query within a React component, call `useGetCampaignParticipantContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignParticipantContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignParticipantContactsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignParticipantContactsQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignParticipantContactsQuery, GetCampaignParticipantContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignParticipantContactsQuery, GetCampaignParticipantContactsQueryVariables>(GetCampaignParticipantContactsDocument, options);
      }
export function useGetCampaignParticipantContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignParticipantContactsQuery, GetCampaignParticipantContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignParticipantContactsQuery, GetCampaignParticipantContactsQueryVariables>(GetCampaignParticipantContactsDocument, options);
        }
export type GetCampaignParticipantContactsQueryHookResult = ReturnType<typeof useGetCampaignParticipantContactsQuery>;
export type GetCampaignParticipantContactsLazyQueryHookResult = ReturnType<typeof useGetCampaignParticipantContactsLazyQuery>;
export type GetCampaignParticipantContactsQueryResult = Apollo.QueryResult<GetCampaignParticipantContactsQuery, GetCampaignParticipantContactsQueryVariables>;